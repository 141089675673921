import React, { useState, useEffect } from 'react'

const Results = React.memo(({ search, results }) => {

	const [ list, setList ] = useState([])

	useEffect(() => {
		var temp = []
		results.forEach(res => {
			try {
				let string = JSON.stringify(res) //eslint-disable-line
				temp.push({ name: res.name, url: res.url, content: string })
			} catch(e) { console.error(e) }
		})
		setList(temp)
	},[]) //eslint-disable-line

	if (search) {
		return (
			<div className='results'>
				{list.map((res, i) => {
					if (res.content.toLowerCase().includes(encodeURIComponent(search)) || res.content.replace(/-/g, ' ').toLowerCase().includes(encodeURIComponent(search))) {
						return <a key={i} className='res' href={res.url}>
							<h2>{res.name}</h2>
						</a>
					} else return false
				})}
			</div>
		)
	}
})

export default Results